
import { graphql } from "gatsby"
import React from 'react';
import Row from "react-bootstrap/Row"
import {Col,Container} from "react-bootstrap"
import * as style from './TextWithTwoBoxes.module.scss';
import { createLocalLink } from "../../utils"
import {GatsbyImage, getImage} from "gatsby-plugin-image";


export const fragment = graphql`
  fragment TextWithTwoBoxesFragment on WpPage_Flexlayouts_FlexibleLayouts_TextWithTwoBoxes {  
      paragraph
      boxes {
        header
        text
        image {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 510
                quality: 75
                placeholder: NONE
              )
            }
          }
        }
        link {
          title
          url
        }
      }
}
`
const TextWithTwoBoxes = ({boxes,paragraph}) => {

  return (
    <section className={`section mt-lg-xlarge ${style.sectionDiv}`} >
      <Container>
         <Row  className="py-5">
          <Col lg={4} xl={5} className="px-xl-0">

              <div className={`h2 font-weight-normal pt-lg-xlarge ${style.text}`} dangerouslySetInnerHTML={{ __html: paragraph }} />

          </Col>

          <Col lg={8} xl={7} className={`d-flex flex-column justify-content-center mx-auto flex-md-row px-xl-0`}>
            {
              boxes.map((item,i) => {
                return (
                  <div  className=" py-2 pt-xxl-4 px-md-2" key={i}>
                    <div className={`${style.box}`}>
                      <div className="pl-4">
                        <h3 className="text-uppercase pt-4" dangerouslySetInnerHTML={{ __html: item.header }} />
                        <div className="mb-0 mt-3" dangerouslySetInnerHTML={{ __html: item.text }} />
                      </div>
                      <a href={createLocalLink(item.link.url)} >
                        <p className="mt-2 pl-4" dangerouslySetInnerHTML={{ __html: item.link.title }} />
                      </a>
                        {item.image && <GatsbyImage
                            image={getImage(item.image.localFile)}
                            alt={item.image.altText ? item.image.altText : "Image supporting text"}
                            className="w-100"/>}
                    </div>
                  </div>
                )
              })
            }
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TextWithTwoBoxes
